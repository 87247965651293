<template>
  <section>
    <pm-Dialog
      modal
      :header="'Área de Conhecimento'"
      v-model:visible="modal1"
      :style="{ width: '70vw' }"
      :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
    >
      <div class="col-12 grid justify-content-end m-0">
        <pm-Button
          type="button"
          @click="registrarAreaConhecimento()"
          label="CADASTRAR NOVA AREA"
          icon="pi pi-external-link"
          title="CADASTRAR NOVA AREA"
          class="p-button-success"
        ></pm-Button>
      </div>
      <pm-DataTable
        :value="areasConhecimento"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
      >
        <pm-Column
          field="nome"
          sortable
          header="NOME"
          class="colunn-tbl"
        ></pm-Column>
        <pm-Column
          field="id"
          headerStyle="width: 4rem; text-align: center"
          bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important"
          class="colunn-tbl2"
        >
          <template #body="{ data }">
            <pm-Button
              type="button"
              @click="modalEditarAreaConhecimento(data)"
              icon="pi pi-pencil"
              label="Editar"
              title="Editar"
              v-if="situacaoAno && acesso.editar"
              class="p-button-sm btn-color;table_style"
            ></pm-Button>
            <pm-Button
              title="Apagar"
              type="button"
              v-if="situacaoAno && acesso.deletar"
              @click="deletarAreaConhecimento(data.id)"
              label="Apagar"
              icon="pi pi-times-circle"
              class="p-button-danger p-button-sm btn-color;table_style"
              style="margin-left: 5px"
            ></pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
    </pm-Dialog>

    <pm-Dialog
      :header="'Área de Conhecimento'"
      v-model:visible="modal2"
      modal
      :style="{ width: '70vw' }"
      :breakpoints="{ '960px': '80vw', '641px': '100vw' }"
    >
      <div class="col-12 grid justify-content-between m-0">
        <pm-Button
          type="button"
          @click="voltarAreaConhecimento()"
          label="voltar"
          icon="pi pi-external-link"
          class="p-button-danger"
        ></pm-Button>
        <pm-Button
          type="button"
          @click="editarAreaConhecimento(areaConhecimento)"
          label="EDITAR"
          icon="pi pi-external-link"
          class="p-button-success"
        ></pm-Button>
        <pm-Button
          type="button"
          @click="criaAreaConhecimento()"
          label="CADASTRAR"
          icon="pi pi-external-link"
          class="p-button-success"
        ></pm-Button>
      </div>

      <div class="col-12 md:col-12 lg:col-3">
        <label>Nome da Área de conhecimento</label>
        <pm-InputText v-model="areaConhecimento.nome" />
      </div>
    </pm-Dialog>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { AreaConhecimento } from "@/class/areaConhecimento.js";

export default defineComponent({
  props: {},
  data() {
    return {
      // modal1: false,
      // modal2: false,
      areasConhecimento: [],
      areaConhecimento: {
        nome: "",
      },
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.disciplinaALL.length / this.perPage)
        : this.disciplinaALL.length;
    },
  },
  methods: {
    async editarAreaConhecimento(area) {
      try {
        const areaConhecimento = await AreaConhecimento.alterar(area);

        if (areaConhecimento.status === 200) {
          this.$vaToast.init({
            message: "Editado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            color: "success",
            fullWidth: false,
          });
          this.areasConhecimento = [];
          this.getAreaConhecimento();
        }
      } catch (error) {
        console.log(error);
      }
    },
    modalEditarAreaConhecimento(area) {
      this.areaConhecimento = area;
      this.modal1 = false;
      this.modal2 = true;
    },
    async deletarAreaConhecimento(id) {
      try {
        const areaConhecimento = await AreaConhecimento.remover(id);
        if (areaConhecimento.status === 200) {
          this.$vaToast.init({
            message: "Deletado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            color: "success",
            fullWidth: false,
          });
          this.areasConhecimento = [];
          this.getAreaConhecimento();
        }
      } catch (error) {}
    },
    async criaAreaConhecimento() {
      try {
        const areaConhecimento = await AreaConhecimento.cadastrar(
          this.areaConhecimento
        );

        if (areaConhecimento.status === 201) {
          this.$vaToast.init({
            message: "Cadastrado com sucesso!",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 2500,
            color: "success",
            fullWidth: false,
          });
          this.areasConhecimento = [];
          this.getAreaConhecimento();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAreaConhecimento() {
      console.log("entrou");
      if (this.areasConhecimento.length === 0) {
        const areasConhecimento = await AreaConhecimento.obtemTodos();
        this.areasConhecimento = areasConhecimento.data;
      }
      this.modal2 = false;
      this.modal1 = true;
    },
    registrarAreaConhecimento() {
      this.modal1 = false;
      this.modal2 = true;
    },
    voltarAreaConhecimento() {
      this.modal2 = false;
      this.modal1 = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
